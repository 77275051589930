import { Combobox as HeadlessUICombobox, Transition } from '@headlessui/react';
import cn from 'classnames';
import React, { Fragment } from 'react';
import AbstractInput from 'styleguide/components/Formik/AbstractInput/AbstractInput';
import { IconClose } from 'styleguide/icons';
import Option from './Option';
import { FieldInputProps, FormikValues, useFormikContext } from 'formik';
import { flatten } from 'utils/flatten';
import { UiSize } from 'app/styleguide/styles/sizes';

type OptionType = {
  key: string | number;
} & (
  | {
      label: string;
    }
  | {
      name: string;
    }
);

interface OptGroup {
  label: string;
  values: Array<Record<'key' | 'label', string>>;
}

interface Props {
  field: FieldInputProps<string>;
  size: UiSize;
  options?: OptionType[];
  optionGroups?: OptGroup[];
  autoSelect?: boolean;
  autoSelectMinimumLength?: number;
  autoSelectComparator?: (inputValue: string, optionValue: string) => boolean;
  Tooltip?: React.ReactNode;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  withClear?: boolean;
  creatable?: boolean;
  isMulti?: boolean;
  className?: string;
  disabled?: boolean;
  inPlaceError?: boolean;
}

const Combobox = ({
  field,
  size,
  options = [],
  optionGroups = [],
  autoSelect = false,
  autoSelectMinimumLength = 3,
  autoSelectComparator,
  Tooltip = null,
  onChange = () => {
    // noop
  },
  withClear = false,
  creatable = false,
  isMulti = false,
  disabled = false,
  inPlaceError = true,
  className,
  ...props
}: Props) => {
  const { values, setFieldValue } = useFormikContext<FormikValues>();
  const selectedValues = () => {
    let selected;
    if (isMulti) {
      const valueKeys = flatten(values)[field.name];
      selected = [
        options.filter(option => valueKeys.includes(option.key)),
        ...optionGroups.map(group => group.values.filter(option => valueKeys.includes(option.key))),
      ];
    } else {
      const valueKey = flatten(values)[field.name];
      selected =
        options.find(option => valueKey === option.key) ||
        optionGroups
          .map(group => group.values.find(option => valueKey === option.key))
          .find(option => !!option);
    }

    if (!selected) {
      return null;
    }

    return selected;
  };
  const [query, setQuery] = React.useState('');

  const getValueName = val => {
    if (!val) {
      return '';
    }
    if (val.label) {
      return val.label;
    }
    if (val.name) {
      return val.name;
    }
    return val;
  };
  const filterValues = selectValues =>
    query === ''
      ? selectValues
      : selectValues.filter(filteredVal =>
          getValueName(filteredVal).toLowerCase().includes(query.toLowerCase()),
        );

  const filteredValueGroups = optionGroups.map(group => ({
    [getValueName(group)]: filterValues(group.values),
  }));
  const filteredData = filterValues(options);

  const onValueChanged = val => {
    setFieldValue(field.name, val.key);
    onChange(val);
  };
  const onMultipleValuesChanged = changedValues => {
    setFieldValue(
      field.name,
      changedValues.map(val => val.key),
    );
    onChange(changedValues);
  };
  const IsFilteredValueGroupsEmpty = () =>
    filteredValueGroups.every(group => Object.values(group)[0].length === 0);
  const removeValFromSelectedOptions = removedVal => {
    const newSelectedValues = values.filter(val => val !== removedVal.key);
    onMultipleValuesChanged(newSelectedValues);
  };
  const isEmptySearchResults = () => filteredData.length === 0 && IsFilteredValueGroupsEmpty();

  const clearSelected = () => {
    setQuery('');
    setFieldValue(field.name, null);
    onChange(null);
  };

  const matchInputToOption = (inputValue: string) => {
    const input = inputValue.trim().toLowerCase();
    const matchedOption = options.find(option => {
      const name = getValueName(option).toString().toLowerCase().trim();
      const key = option.key.toString().toLowerCase().trim();

      if (autoSelectComparator) {
        return autoSelectComparator(input, name) || autoSelectComparator(input, key);
      }

      return name.indexOf(input) > -1 || key.indexOf(input) > -1;
    });
    return matchedOption || null;
  };

  const ref = React.useRef(null);

  const displayValue = val => {
    if (!isMulti) {
      return getValueName(val);
    }
    return Array.isArray(val) ? val.map(v => getValueName(v)).join(', ') : getValueName(val);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!isMulti && autoSelect && event.target.value.length >= autoSelectMinimumLength) {
      const matchedOption = matchInputToOption(inputValue);
      if (matchedOption) {
        onValueChanged(matchedOption);
        setQuery('');

        // hack to trigger the close of the dropdown
        setTimeout(() => {
          const esc = new KeyboardEvent('keydown', {
            key: 'Escape',
            keyCode: 27,
            code: 'Escape',
            bubbles: true,
            cancelable: true,
          });
          ref.current.focus();
          ref.current.dispatchEvent(esc);
          ref.current.blur();
        }, 100);

        return;
      }
    }

    setQuery(inputValue);
  };

  return (
    <HeadlessUICombobox
      value={selectedValues()}
      onChange={isMulti ? onMultipleValuesChanged : onValueChanged}
      // multiple={isMulti}
    >
      <div className="relative">
        <HeadlessUICombobox.Button as="div" className="group cursor-pointer">
          <AbstractInput
            {...field}
            // @ts-ignore
            setRef={ref}
            disabled={disabled}
            component="Combobox"
            className={cn(className, withClear ? 'pr-10' : '', isMulti ? '!text-shades-0' : '')}
            onChange={handleInputChange}
            size={size || 'md'}
            displayValue={displayValue}
            inPlaceError
            hideErrorMessage={!inPlaceError}
            {...props}
          >
            {isMulti && values.length > 0 && (
              <div className="-mt-8 flex w-[98%] flex-wrap">
                {selectedValues().map(val => (
                  <div key={val.id} className="mb-2 ml-2 mr-2 rounded-md bg-blue p-1 text-shades-0">
                    <div className="flex">
                      <div className="text-sm">{val.name}</div>
                      <IconClose
                        className="ml-2 !h-2 !w-2 cursor-pointer"
                        color="white"
                        onClick={() => removeValFromSelectedOptions(val)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </AbstractInput>
        </HeadlessUICombobox.Button>

        {withClear && (
          <div
            className={cn(
              'absolute right-7 top-1/2 z-90 flex h-5 -translate-y-1/2 items-center border-r border-solid border-gray-300 pr-2',
              inPlaceError ? '-mt-2' : '',
            )}
            onClick={clearSelected}
            onKeyDown={clearSelected}
            role="button"
            tabIndex={0}
          >
            <IconClose className="!h-2 !w-2 text-default" />
          </div>
        )}
        {!!Tooltip && (
          <div className="absolute right-7 top-1/2 z-[5] flex h-5 -translate-y-1/2">{Tooltip}</div>
        )}
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <HeadlessUICombobox.Options
            className={cn(
              `absolute z-10 -mt-1 max-h-60 w-full overflow-auto rounded-md bg-shades-0 
              py-1 text-base shadow-lg ring-1 ring-shades-100 ring-opacity-5 focus:outline-none sm:text-sm`,
              inPlaceError ? '-mt-6' : '',
            )}
          >
            {filteredValueGroups.map(
              group =>
                Object.values(group)[0].length > 0 && (
                  <>
                    <div className="my-1">
                      <span className="pl-3 font-hvBold text-xs font-bold leading-4 text-gray-500">
                        {Object.keys(group)[0].toUpperCase()}
                      </span>
                    </div>

                    {Object.values(group)[0].map((val, index) => (
                      <Option
                        key={`${val.key}${index}`}
                        value={val}
                        name={val.outOfStock ? `${getValueName(val)} (out of stock)` : getValueName(val)}
                        disabled={val.outOfStock}
                      />
                    ))}
                  </>
                ),
            )}
            {filteredData.map((val, index) => (
              <Option
                key={`${val.key}${index}`}
                value={val}
                name={val.outOfStock ? `${getValueName(val)} (out of stock)` : getValueName(val)}
                disabled={val.outOfStock}
              />
            ))}
            {creatable && isEmptySearchResults() && query.length > 0 && (
              <Option key={query} value={{ label: query, value: query }} name={`Create ${query}`} />
            )}
            {!creatable && isEmptySearchResults() && query.length > 0 && (
              <div className="px-3 py-2 text-gray-500">Nothing found</div>
            )}
          </HeadlessUICombobox.Options>
        </Transition>
      </div>
    </HeadlessUICombobox>
  );
};

Combobox.Option = Option;

export default Combobox;
