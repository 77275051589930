import * as React from 'react';
import { Combobox } from '@headlessui/react';
import cn from 'classnames';

interface Props {
  value?: { value: string; label: string };
  name?: string;
  disabled?: boolean;
}

const ComboboxOption = ({ value, name, ...props }: Props) => (
  <Combobox.Option
    value={value}
    disabled={props.disabled}
    className={({ active, disabled }) =>
      cn(
        'relative cursor-default select-none py-2 pl-3 pr-9',
        active ? 'bg-blue-50 text-default' : 'text-default',
        disabled ? '!text-gray-500' : '',
      )
    }
  >
    <span data-cy={`${name}`} className={cn('block text-sm font-medium')}>
      {name}
    </span>
  </Combobox.Option>
);

export default ComboboxOption;
